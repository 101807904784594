<template>
  <b-modal id="exclude-document-modal" hide-header hide-footer centered>
    <div class="header">
      <div class="header-content">
        <p class="header-content-text">Excluir documento anexado</p>
        <Close class="close-icon" @click="closeExcludeDocumentModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <p class="body-content-text">
          Você tem certeza que deseja excluir o documento
          <span class="body-content-text-highlighted">
            {{ getDocumentName(document) }}
          </span>
          ? Não será possível reverter essa ação.
        </p>
      </div>
    </div>

   <div class="footer">
      <div class="footer-content">
        <button
          class="footer-content-cancel-button"
          @click="closeExcludeDocumentModal"
        >
          Cancelar
        </button>
        <button class="footer-content-confirm-button" @click="deleteFile">
          Confirmar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '../../assets/icons/close.svg'

export default {
  name: 'ExcludeDocumentModal',
  components: {
    Close
  },
  props: {
    document: Array,
    getAllDocs: { type: Function, required: true },
    getDocumentName: Function
  },
  methods: {
    closeExcludeDocumentModal() {
      this.form = {}
      this.getAllDocs()
      this.$bvModal.hide('exclude-document-modal')
    },
    deleteFile() {
      const isLoading = this.$loading.show()
      this.api
        .deleteAttendanceFile(this.document[0].id)
        .then(async res => {
          this.$toast.success('Excluído com sucesso!')
          this.closeExcludeDocumentModal()
        })
        .finally(() => isLoading.hide())
    }
  }
}
</script>

<style lang="scss">
#exclude-document-modal {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 448px;

    .modal-body {
      padding: 0;

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 24px;

          .header-content-text {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }

          .close-icon {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        .body-content {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          margin: 24px 24px 56px 24px;

          .body-content-text-highlighted {
            color: var(--blue-500);
          }
        }
      }

      .footer {
        display: flex;
        justify-content: right;
        margin: 0 24px 24px 24px;
        .footer-content {
          display: flex;
          flex-direction: row;

          .footer-content-cancel-button {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--states-error);
            padding: 8px 16px;
            background-color: var(--neutral-000) !important;
          }

          .footer-content-confirm-button {
            background: var(--blue-500);
            border-radius: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--neutral-000);
            padding: 8px 16px;
          }
        }
      }
    }
  }
}
</style>
